body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.song-tab{
    width:clac(100% - 10px);
    border-radius: 5px;
    margin:5px;
}

.song-tab-main{
    float:left;
    width:75%;
}

.song-tab-controls{
    float:right;
    width:fit-content;
}

.inputdiv{
    width:clac(100% - 10px);
    background-color: grey;
    border-radius: 5px;
    margin:5px;
}

.card{
    margin:5px;
    background-color: green;
}

.col > h3,.col > p,.col > i,.col-auto i{
    color:white;
}

.song > .card-body{
    padding:2px;
}

p{
    padding:0;
    margin:0;
    color:white;
}
.song > .card-body > .row > .col p{
    text-align: left;
}

.song > .card-body > .row > .col{
    text-align: center;
}

hr{
    margin:0 auto;
    padding:0;
    width:75%;
    color:white;
}

.form-floating.ml.btn-group{
    width:100%;
}

input[type="radio"]{
    margin-left: 5px;
    margin-right:3px;
}

.form-floating label{
    color:black;
}

label{
    color:white;
}